<template>
  <b-card>
    <validation-observer ref="odemeData">
      <b-form>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Ödeme Seri No')" label-for="paySeriNo">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Ödeme Seri No"
              >
                <b-input-group>
                  <b-form-input
                    id="paySeriNo"
                    v-model="odemeDataLocal.paySeriNo"
                    name="paySeriNo"
                    :placeholder="$t('Ödeme Seri No')"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Ödeme ID')" label-for="payMerchantId">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Ödeme ID"
              >
                <b-input-group>
                  <b-form-input
                    id="payMerchantId"
                    v-model="odemeDataLocal.payMerchantId"
                    name="payMerchantId"
                    :placeholder="$t('Ödeme ID')"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Ödeme Anahtarı')" label-for="payMerchantKey">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Ödeme Anahtarı"
              >
                <b-input-group>
                  <b-form-input
                    id="payMerchantKey"
                    v-model="odemeDataLocal.payMerchantKey"
                    name="payMerchantKey"
                    :placeholder="$t('Ödeme Anahtarı')"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
           <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Salt')" label-for="payMerchantSalt">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Salt"
              >
                <b-input-group>
                  <b-form-input
                    id="payMerchantSalt"
                    v-model="odemeDataLocal.payMerchantSalt"
                    name="payMerchantSalt"
                    :placeholder="$t('Salt')"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
           <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('İşlem Zaman Aşımı (dk)')" label-for="payTimoutLimit">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="İşlem Zaman Aşımı (dk)"
              >
                <b-input-group>
                  <b-form-input
                  type="number"
                    id="payTimoutLimit"
                    v-model="odemeDataLocal.payTimoutLimit"
                    name="payTimoutLimit"
                    :placeholder="$t('İşlem Zaman Aşımı (dk)')"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group label-for="payMerchantOkUrl" :label="$t('Başarılı Ödeme URL')">
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('Başarılı Ödeme URL')"
              >
                <b-input-group>
                  <b-form-input
                    id="payMerchantOkUrl"
                    v-model="odemeDataLocal.payMerchantOkUrl"
                    :name="$t('Başarılı Ödeme URL')"
                    :placeholder="$t('Başarılı Ödeme URL')"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- retype password -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group label-for="payMerchantFailUrl" :label="$t('Başarısız Ödeme URL')">
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('Başarılı Ödeme URL')"
              >
                <b-input-group>
                  <b-form-input
                    id="payMerchantFailUrl"
                    v-model="odemeDataLocal.payMerchantFailUrl"
                    name="payMerchantFailUrl"
                    :placeholder="$t('Başarısız Ödeme URL')"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Diğer')" label-for="checkboxes">
              <b-form-checkbox v-model="odemeDataLocal.payDebugOn">{{ $t("Debug Modu") }}</b-form-checkbox>
              <b-form-checkbox v-model="odemeDataLocal.payTestMode">{{ $t("Production'da Test Modu") }}</b-form-checkbox>
              <b-form-checkbox v-model="odemeDataLocal.payNoInstallment">{{ $t("Taksitlendirme") }}</b-form-checkbox>
              <b-form-checkbox :disabled="!odemeDataLocal.payNoInstallment" v-model="odemeDataLocal.payMaxInstallment">{{ $t("Taksit Adedi Sınırlandırma") }}</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <save-button
              :onClickSave="saveOdemeAyar"
              :showSaveAndClose="false"
              :showClose="false"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SaveButton from '@/components/SaveButton.vue';
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import AlertService from '@/common/alert.service'

export default {
  props: {
    odemeData: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      required,
      odemeDataLocal: {
        paySeriNo: '',
        payMerchantId: '',
        payMerchantKey: '',
        payMerchantSalt: '',
        payMerchantOkUrl: '',
        payMerchantFailUrl: '',
        payTimoutLimit: 5,
        payDebugOn: false,
        payTestMode: false,
        payNoInstallment: false,
        payMaxInstallment: false,
      },
    }
  },
  components: {
    SaveButton,
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    this.odemeDataLocal = this.odemeData;
  },
  methods: {
    saveOdemeAyar() {
      this.$refs.odemeData.validate().then(success => {
        if (success) {
          this.$store.dispatch('saveOdemeAyar', this.odemeDataLocal).then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
            } else {
              AlertService.error(this, response.message)
            }
          });
        }
      })
    },
  },
}
</script>

<style>
</style>
