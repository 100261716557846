<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">ERP</span>
      </template>
      <ERPBaglanti
        v-if="getGenelAyars.erpAyar"
        :erp-data="getGenelAyars.erpAyar"
      />
    </b-tab>
    <!--/ general tab -->
    <!-- payment tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="CreditCardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Ödeme') }}</span>
      </template>
      <odeme v-if="getGenelAyars.odemeAyar" :odemeData="getGenelAyars.odemeAyar" />
    </b-tab>
    <!--/ payment tab -->
    
  </b-tabs>
</template>
<script>
import { mapGetters } from 'vuex';
import ERPBaglanti from './ayarlar/ERPBaglanti.vue';
import Odeme from './ayarlar/Odeme.vue';

export default {
    components: {
        ERPBaglanti,
        Odeme,
    },
    data() {
      return {
        ayarlar: {},
      }
    },
    computed: {
      ...mapGetters(['getGenelAyars']),
    },
    mounted() {
        this.$store.dispatch('fetchGenelAyars');
    },
   
}
</script>
