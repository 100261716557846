var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"odemeData"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ödeme Seri No'),"label-for":"paySeriNo"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ödeme Seri No"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"paySeriNo","name":"paySeriNo","placeholder":_vm.$t('Ödeme Seri No')},model:{value:(_vm.odemeDataLocal.paySeriNo),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "paySeriNo", $$v)},expression:"odemeDataLocal.paySeriNo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ödeme ID'),"label-for":"payMerchantId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ödeme ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"payMerchantId","name":"payMerchantId","placeholder":_vm.$t('Ödeme ID')},model:{value:(_vm.odemeDataLocal.payMerchantId),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "payMerchantId", $$v)},expression:"odemeDataLocal.payMerchantId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ödeme Anahtarı'),"label-for":"payMerchantKey"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ödeme Anahtarı"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"payMerchantKey","name":"payMerchantKey","placeholder":_vm.$t('Ödeme Anahtarı')},model:{value:(_vm.odemeDataLocal.payMerchantKey),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "payMerchantKey", $$v)},expression:"odemeDataLocal.payMerchantKey"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Salt'),"label-for":"payMerchantSalt"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Salt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"payMerchantSalt","name":"payMerchantSalt","placeholder":_vm.$t('Salt')},model:{value:(_vm.odemeDataLocal.payMerchantSalt),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "payMerchantSalt", $$v)},expression:"odemeDataLocal.payMerchantSalt"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('İşlem Zaman Aşımı (dk)'),"label-for":"payTimoutLimit"}},[_c('validation-provider',{attrs:{"rules":"required","name":"İşlem Zaman Aşımı (dk)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","id":"payTimoutLimit","name":"payTimoutLimit","placeholder":_vm.$t('İşlem Zaman Aşımı (dk)')},model:{value:(_vm.odemeDataLocal.payTimoutLimit),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "payTimoutLimit", $$v)},expression:"odemeDataLocal.payTimoutLimit"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label-for":"payMerchantOkUrl","label":_vm.$t('Başarılı Ödeme URL')}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Başarılı Ödeme URL')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"payMerchantOkUrl","name":_vm.$t('Başarılı Ödeme URL'),"placeholder":_vm.$t('Başarılı Ödeme URL')},model:{value:(_vm.odemeDataLocal.payMerchantOkUrl),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "payMerchantOkUrl", $$v)},expression:"odemeDataLocal.payMerchantOkUrl"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label-for":"payMerchantFailUrl","label":_vm.$t('Başarısız Ödeme URL')}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Başarılı Ödeme URL')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"payMerchantFailUrl","name":"payMerchantFailUrl","placeholder":_vm.$t('Başarısız Ödeme URL')},model:{value:(_vm.odemeDataLocal.payMerchantFailUrl),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "payMerchantFailUrl", $$v)},expression:"odemeDataLocal.payMerchantFailUrl"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Diğer'),"label-for":"checkboxes"}},[_c('b-form-checkbox',{model:{value:(_vm.odemeDataLocal.payDebugOn),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "payDebugOn", $$v)},expression:"odemeDataLocal.payDebugOn"}},[_vm._v(_vm._s(_vm.$t("Debug Modu")))]),_c('b-form-checkbox',{model:{value:(_vm.odemeDataLocal.payTestMode),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "payTestMode", $$v)},expression:"odemeDataLocal.payTestMode"}},[_vm._v(_vm._s(_vm.$t("Production'da Test Modu")))]),_c('b-form-checkbox',{model:{value:(_vm.odemeDataLocal.payNoInstallment),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "payNoInstallment", $$v)},expression:"odemeDataLocal.payNoInstallment"}},[_vm._v(_vm._s(_vm.$t("Taksitlendirme")))]),_c('b-form-checkbox',{attrs:{"disabled":!_vm.odemeDataLocal.payNoInstallment},model:{value:(_vm.odemeDataLocal.payMaxInstallment),callback:function ($$v) {_vm.$set(_vm.odemeDataLocal, "payMaxInstallment", $$v)},expression:"odemeDataLocal.payMaxInstallment"}},[_vm._v(_vm._s(_vm.$t("Taksit Adedi Sınırlandırma")))])],1)],1)],1),_c('b-row',[_c('b-col',[_c('save-button',{attrs:{"onClickSave":_vm.saveOdemeAyar,"showSaveAndClose":false,"showClose":false}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }