<template>
  <b-card>
    <validation-observer ref="erpData">
      <b-form>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group
              :label="$t('Kullanıcı Adı')"
              label-for="sErpKullaniciAdi"
            >
             <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Adı"
                >
              <b-input-group>
               
                  <b-form-input
                    id="sErpKullaniciAdi"
                    v-model="erpDataLocal.sErpKullaniciAdi"
                    name="sErpKullaniciAdi"
                    :placeholder="$t('Kullanıcı Adı')"
                  />
              </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Parola')" label-for="sErpParola">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('Parola')"
                >
              <b-input-group>
                  <b-form-input
                    id="sErpParola"
                    v-model="erpDataLocal.sErpParola"
                    name="sErpParola"
                    :placeholder="$t('Parola')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
              </b-input-group>
                </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group label-for="sFirmaSubeKodu" :label="$t('Firma Şube Kodu')">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Adı"
                >
              <b-input-group>
                  <b-form-input
                    id="sFirmaSubeKodu"
                    v-model="erpDataLocal.sFirmaSubeKodu"
                    name="sFirmaSubeKodu"
                    :placeholder="$t('Firma Şube Kodu')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
              </b-input-group>
                </validation-provider>
            </b-form-group>
          </b-col>

          <!-- retype password -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group label-for="sFirmaAdi" :label="$t('Firma Adı')">
              <b-input-group>
                <b-form-input
                  id="sFirmaAdi"
                  v-model="erpDataLocal.sFirmaAdi"
                  name="sFirmaAdi"
                  :placeholder="$t('Firma Adı')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Firma Vergi Kimlik No')" label-for="sFirmaVkn">
              <b-input-group>
                <b-form-input
                  id="sFirmaVkn"
                  v-model="erpDataLocal.sFirmaVkn"
                  name="sFirmaVkn"
                  :placeholder="$t('Firma Vergi Kimlik No')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <save-button
              :onClickSave="saveErpAyar"
              :showSaveAndClose="false"
              :showClose="false"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import SaveButton from '@/components/SaveButton.vue';
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import AlertService from '@/common/alert.service'

export default {
  props: {
    erpData: {
      type: Object,
       default() {
        return {}
      },
    },
  },

  data() {
    return {
      required,
      erpDataLocal: {
        sErpKullaniciAdi: '',
        sErpParola: '',
        sFirmaSubeKodu: '',
        sFirmaAdi: '',
        sFirmaVkn: '',
      },
    }
  },
  components: {
    SaveButton,
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    this.erpDataLocal = this.erpData;
  },
  methods: {
    saveErpAyar() {
      this.$refs.erpData.validate().then(success => {
        if (success) {
          this.$store.dispatch('saveErpAyar', this.erpDataLocal).then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
            } else {
              AlertService.error(this, response.message)
            }
          });
        }
      })
    },
  },
}
</script>

<style>
</style>
